<template>
	<div class="agent_zhaopin">
		<el-card>
			<el-row :gutter="20" class="left">
				<el-col :span="24">
					<span style="color:#666">提现管理</span>
				</el-col>
			</el-row>
			<el-divider></el-divider>
			<el-row class="left">
				<el-col :span="24">
					<el-form :inline="true" class="demo-form-inline">
						<el-form-item label="输入查询">
							<el-input v-model="searchText" placeholder="请输入提现编号" clearable></el-input>
						</el-form-item>
						<el-form-item label="提现时间">
							<el-date-picker v-model="datePicker" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
							</el-date-picker>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
							<el-button type="default" @click="reset" icon="el-icon-refresh">重置</el-button>
						</el-form-item>
					</el-form>
				</el-col>
			</el-row>
			<el-table :data="txList" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
				<el-table-column prop="tixian_id" label="ID"></el-table-column>
				<el-table-column prop="tixian_bh" label="提现编号" width="180px"></el-table-column>
				<el-table-column prop="tixian_type" label="角色" width="100px"></el-table-column>
				<el-table-column prop="user_name" label="角色姓名" width="100px"></el-table-column>
				<el-table-column prop="tixian_total" label="提现金额" width="120px"></el-table-column>
				<!--<el-table-column prop="tixian_mode" label="提现模式" width="100px"></el-table-column>-->
				<el-table-column prop="platform_amount" label="heepay账号余额" width="150px"></el-table-column>
<!--				<el-table-column label="分账状态">-->
<!--					<template slot-scope="scope">-->
<!--				        <el-tag :type="scope.row.tx_status==1?'primary' : 'success'" disable-transitions>{{scope.row.tx_status_name}}</el-tag>-->
<!--				    </template>-->
<!--				</el-table-column>-->
				<el-table-column label="审核状态">
					<template slot-scope="scope">
				        <el-tag :type="scope.row.verify_status==0?'primary' : 'success'" disable-transitions>{{scope.row.verify_status_name}}</el-tag>
				    </template>
				</el-table-column>
				<el-table-column label="到账状态">
					<template slot-scope="scope">
				        <el-tag :type="scope.row.daozhang_status==1?'success' : 'primary'" disable-transitions>{{scope.row.daozhang_status_name}}</el-tag>
				    </template>
				</el-table-column>
				<el-table-column prop="add_time" label="提现时间" width="150px"></el-table-column>
				<el-table-column fixed="right" label="操作" width="180px">
					<template slot-scope="scope">
						<el-row style="margin-bottom:10px" >
							<el-button size="mini" type="primary" v-if="scope.row.verify_status==0" plain @click="goVerify(scope.row)">审核</el-button>
							<el-button size="mini" type="primary" v-else plain @click="goVerify(scope.row)">查看</el-button>
							<el-button size="mini" type="primary" v-if="scope.row.daozhang_status==0" plain @click="queryTianxian(scope.row)">查询到账</el-button>
						</el-row>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页 -->
			<Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
			<!-- 审核对话框 -->
			<el-dialog title="提现审核" width="60%" top="5vh" :visible.sync="showDialog" :modal-append-to-body="false">
				<el-form ref="commonForm" :model="commonForm" label-width="120px">
					<el-row>
						<el-col :span="12">
							<el-form-item label="提现编号" prop="tixian_bh">
								<el-input v-model="commonForm.tixian_bh" disabled></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="提现类型" prop="tixian_type">
								<el-input v-model="commonForm.tixian_type" disabled></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="姓名" prop="user_name">
								<el-input v-model="commonForm.user_name" disabled></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="手机号" prop="phone">
								<el-input v-model="commonForm.phone" disabled></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="提现金额" prop="tixian_total">
								<el-input v-model="commonForm.tixian_total" disabled></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="heepay帐户余额" prop="platform_amount">
								<el-input v-model="commonForm.platform_amount" disabled></el-input>
							</el-form-item>
						</el-col>
<!--						<el-col :span="12">-->
<!--							<el-form-item label="提现模式" prop="tixian_mode">-->
<!--								<el-input v-model="commonForm.tixian_mode" disabled></el-input>-->
<!--							</el-form-item>-->
<!--						</el-col>-->
<!--						<el-col :span="12">-->
<!--							<el-form-item label="分账状态" prop="tx_status_name">-->
<!--								<el-input v-model="commonForm.tx_status_name" disabled></el-input>-->
<!--							</el-form-item>-->
<!--						</el-col>-->
						<el-col :span="12">
							<el-form-item label="到账状态" prop="daozhang_status_name">
								<el-input v-model="commonForm.daozhang_status_name" disabled></el-input>
							</el-form-item>
						</el-col>
						<div v-if="commonForm.verify_status==0">
							<el-col :span="24">
								<el-form-item label="审核结果" prop="verify_status">
									<el-radio v-model="submitForm.verify_status" label="1">通过</el-radio>
									<el-radio v-model="submitForm.verify_status" label="2">不通过</el-radio>
								</el-form-item>
							</el-col>
							<el-col :span="24">
								<el-form-item label="审核意见" prop="verify_content">
									<el-input type="textarea" :rows="5" maxlength="300" show-word-limit v-model="submitForm.verify_content" placeholder="请输入审核意见"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="24" style="text-align:center">
								<el-button type="default" @click="showDialog=false">取消</el-button>
								<el-button type="primary" @click="doSubmit">确定</el-button>
							</el-col>
						</div>
						<div v-else>
							<el-col :span="12">
								<el-form-item label="审核结果" prop="verify_status">
									<el-radio disabled v-model="commonForm.verify_status" label="1">通过</el-radio>
									<el-radio disabled v-model="commonForm.verify_status" label="2">不通过</el-radio>
								</el-form-item>
							</el-col>
							<el-col :span="24">
								<el-form-item label="审核意见" prop="verify_content">
									<el-input type="textarea" disabled :rows="5" maxlength="300" show-word-limit v-model="commonForm.verify_content"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="24" style="text-align:center">
								<el-button type="default" @click="showDialog=false">取消</el-button>
							</el-col>
						</div>
					</el-row>
				</el-form>
			</el-dialog>
		</el-card>
	</div>
</template>

<script>
	let that;
	let timer = null;
	import Pagination from '@/components/Pagination/Pagination.vue'
	export default {
		components: {
			Pagination
		},
		data() {
			return {
				queryInfo: {
					query: "",
					pagenum: 1,
					pagesize: 10,
				},
				tixian_type: "",
				searchText: "",
				datePicker: "",
				total: 0,
				txList: [],
				commonForm: {},
				submitForm: {
					tixian_id: 0,
					verify_status: '1',
					verify_content: '',
				},
				showDialog: false
			};
		},
		created() {
			that = this;
			if(!this.$store.state.userInfo) {
				// 防止未登录
				this.$router.push({
					path: '/login'
				})
			} else {
				this.getTxList();
			}
		},
		methods: {
			getTxList() {
				var url = 'tixian/tx_list';
				let params = {
					tixian_type: this.tixian_type,
					tixian_bh: this.searchText,
					date_time: this.datePicker,
					size: this.queryInfo.pagesize,
					page: this.queryInfo.pagenum,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.total = res.total;
						that.txList = res.list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			// 搜索
			search() {
				this.getTxList();
			},
			// 重置
			reset() {
				this.searchText = "";
				this.datePicker = "";
				this.getTxList();
			},
			//页数改变
			handleSizeChange(newSize) {
				this.queryInfo.pagenum = 1;
				this.queryInfo.pagesize = newSize;
				this.getTxList();
			},
			//页码改变
			handleCurrentChange(newPage) {
				this.queryInfo.pagenum = newPage;
				this.getTxList();
			},
			goVerify(item) {
				this.commonForm = item;
				this.submitForm.tixian_id = item.tixian_id;
				this.showDialog = true;
			},
			doSubmit() {
				if(timer != null) {
					clearTimeout(timer);
				}
				timer = setTimeout(() => {
					var url = 'tixian/goVerify';
					let params = that.submitForm;
					that.fd_post(url, params).then((res) => {
						that.showDialog = false;
						if(res.status) {
							that.$message.success("操作成功");
							that.getTxList();
						} else {
							that.$message.error(res.msg);
						}
					}).catch((err) => {
						that.showDialog = false;
						that.$message.error('网络错误');
					});
				}, 300);
			},
			queryTianxian(item){
				if(timer != null) {
					clearTimeout(timer);
				}
				timer = setTimeout(() => {
					var url = 'tixian/query_tianxian';
					let params = {
						tixian_id: item.tixian_id
					};
					that.fd_post(url, params).then((res) => {
						console.log(res);
						if(res.status) {
							that.$message.success("查询成功");
							that.getTxList();
						} else {
							that.$message.error(res.msg);
						}
					}).catch((err) => {
						that.$message.error('网络错误');
					});
				}, 300);
			}
		},
	};
</script>
<style lang="scss" scoped>
 	.agent_zhaopin{
 		.el-row {
			text-align: left;
			margin-bottom: 20px;
		}
		.mr3 {
			margin-right: 5px;
		}
		
		.left {
			text-align: left;
			margin-bottom: 20px;
		}
 	}
</style>